//import { AuseAuthState } from "../../../Contexts/Admin/AdminAuthContext"
import LocalStorageService from "./LocalStorage";
import * as Icons from "@material-ui/icons";
import Dot from '../../../Components/Admin/Dot'

export const SideNavBarList = () => {
    var role = LocalStorageService.getRole();

    if (role === "super" || role === "admin") {
        return [
            { id: 0, label: "Dashboard", link: "/admin/dashboard", icon: <Icons.Home /> },
            {
                id: 1,
                label: "User",
                link: "/admin/users",
                icon: <Icons.People />,
                children: [
                    { label: "List", link: "/admin/users/list" },
                    { label: "Live Sessions", link: "/admin/users/live" },
                    { label: "Blocked Ip", link: "/admin/users/blockedip" },
                    { label: "Roles", link: "/admin/users/roles" },
                    { label: "Followers", link: "/admin/users/follower_count" }
                ],
            },
            { id: 2, label: "Posts", link: "/admin/posts", icon: <Icons.Wallpaper /> },
            {
                id: 3,
                label: "Packages",
                link: "/admin/packages",
                icon: <Icons.Redeem />
            },
            {
                id: 4,
                label: "Stickers",
                link: "/admin/stickers",
                icon: <Icons.Star />,
            },
            {
                id: 5,
                label: "Banners",
                link: "/admin/banners",
                icon: <Icons.ViewCarousel />
            },
            {
                id: 6,
                label: "Diamond",
                link: "/admin/diamond",
                icon: <Icons.Details />
            },
            {
                id: 7,
                label: "Coupon Code",
                link: "/admin/coupon",
                icon: <Icons.Assessment />
            },
            {
                id: 8,
                label: "Agency",
                link: "/admin/agency",
                icon: <Icons.Accessibility />
            },
            { id: 9, label: "Moods", link: "/admin/moods", icon: <Icons.Mood /> },
            { id: 10, label: "Languages", link: "/admin/languages", icon: <Icons.Language /> },
            { id: 11, label: "App Versions", link: "/admin/app-versions", icon: <Icons.Android /> },
            { id: 12, label: "Wallet Offers", link: "/admin/wallet-offers", icon: <Icons.LocalOffer /> },
            { id: 13, label: "User Badges", link: "/admin/user-badges", icon: <Icons.Face /> },
            { id: 14, label: "Event", link: "/admin/event", icon: <Icons.Event /> },
            {
                id: 15,
                label: "Miscellaneous",
                link: "/admin/miscellaneous/golivestats",
                icon: <Icons.Apps />,
                children: [
                    { label: "Go Live Stats", link: "/admin/miscellaneous/golivestats" },
                    { label: "Withdrawal", link: "/admin/miscellaneous/withdrawal" },
                    { label: "Push Notification", link: "/admin/pushnotification" },
                    { label: "Diamond Stats", link: "/admin/miscellaneous/diamondstats" },
                    { label: "Creator Stats", link: "/admin/miscellaneous/creatorstats" },
                    { label: "Historical Data", link: "/admin/miscellaneous/historicaldata" },
                    { label: "Free Goodies", link: "/admin/freegoodies" },
                ],
            },
            { id: 16, type: "divider" },
            { id: 17, type: "title", label: "Description" },
            {
                id: 18,
                label: "Read Me",
                link: "/admin/readme",
                icon: <Icons.ChromeReaderMode />,
            },
            // {
            //     id: 16,
            //     label: "Send Email",
            //     link: "/admin/send-email",
            //     icon: <Icons.MailOutline />,
            // }
        ];
    }
    else if (role === "agency") {
        return [
            { id: 0, label: "Dashboard", link: "/app/dashboard" },
            {
                id: 1,
                label: "Typography",
                link: "/app/typography",

            },
            { id: 2, label: "Tables", link: "/app/tables" },
            {
                id: 3,
                label: "Notifications",
                link: "/app/notifications",

            },
            {
                id: 4,
                label: "UI Elements",
                link: "/app/ui",

                children: [
                    { label: "Icons", link: "/app/ui/icons" },
                    { label: "Charts", link: "/app/ui/charts" },
                    { label: "Maps", link: "/app/ui/maps" },
                ],
            },
            { id: 5, type: "divider" },
            { id: 6, type: "title", label: "HELP" },
            { id: 7, label: "Library", link: "https://flatlogic.com/templates" },
            { id: 8, label: "Support", link: "https://flatlogic.com/forum" },
            { id: 9, label: "FAQ", link: "https://flatlogic.com/forum" },
            { id: 10, type: "divider" },
            { id: 11, type: "title", label: "PROJECTS" },
            {
                id: 12,
                label: "My recent",
                link: "",
                icon: <Dot size="small" color="warning" />,
            },
            {
                id: 13,
                label: "Starred",
                link: "",
                icon: <Dot size="small" color="primary" />,
            },
            {
                id: 14,
                label: "Background",
                link: "",
                icon: <Dot size="small" color="secondary" />,
            },
        ];
    }

}