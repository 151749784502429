import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../Assets/Images/Web/logo.png'
import Icon from '@mdi/react'
//icons
import {
    mdiFacebook as FacebookIcon,
    mdiInstagram as InstagramIcon,
    mdiYoutube as YouTubeIcon,
} from '@mdi/js'

export default function Footer(props) {
    var footerclassName = "footer-area footer-bg";
    // if (ViewBag.isHome == true) {
    //     footerclassName = "";
    // }
    return (
        <div>
            <footer className={footerclassName} id="contactus">
                <div className="container subscribe-container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-center">
                                <span style={{
                                    fontWeight: '600',
                                    color: 'red',
                                }}>
                                    {/* {props.data.emailError} */}
                                </span>
                            </div>
                            <div className="subscribe-form">
                                <div className="form">
                                    <div className="form-group">
                                        <input type="email" className="form-control"
                                            id="exampleInputEmail1"
                                            name="email"
                                            // value={props.data.email}
                                            // onChange={props.handleOnInputChange}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter your email" autoComplete="off" />
                                        {/* {(props.data.emailError !== '' ?
                                            <button type="submit" disabled
                                                className="btn btn-lg btn-block"
                                                onClick={() => props.addEmail(props.data.email)}
                                            >Subscribe</button> : */}
                                            <button type="submit"
                                                className="btn btn-lg btn-block"
                                                // onClick={() => props.addEmail(props.data.email)}
                                            >Subscribe</button>
                                        {/* )} */}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="footer-items">
                                    <Link className="navbar-brand" to="/">
                                        <img className="logo" src={logo} alt="" />
                                    </Link>
                                    <p className="mt-2 mb-3 text-white">An amazing app to get rating percentage on your pics, get famous & make your friends & family member famous in the digital world. At the same time play with your pictures with amazing tools or create a digital challenge.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-5 ">
                                <h3 className="footer-title mb-2 text-white text-center mr-lg-5 pe-lg-5">Useful Links</h3>
                                {/* Footer Items  */}
                                <div className="d-flex">
                                    <div className="footer-items col-sm-6">
                                        {/* Footer Title  */}

                                        <ul className="pt-lg-2">
                                            <li className="py-lg-2 py-1">
                                                <Link to="/gdpr" className="text-white" rel="nofollow">GDPR</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/eula" className="text-white" rel="nofollow">EULA</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/terms-of-use" className="text-white">Terms of Service</Link>
                                            </li>
                                            {/* <li className="py-lg-2 py-1"><a href="@Url.Action(" ContestRules")" className="text-white">VooHoo- Video Banao Fame Pao Contest Rules</a></li> */}
                                            <li className="py-lg-2 py-1">
                                                <Link to="/Copyright-policy" className="text-white">Copyright Policy</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/anti-phishing" className="text-white">Anti-Phishing</Link>
                                            </li>

                                        </ul>
                                    </div>

                                    {/* Footer Items  */}
                                    <div className="footer-items col-sm-6 ml-lg-0 ml-5 ps-lg-0 ps-4">
                                        <ul className="pt-lg-2">
                                            <li className="py-lg-2 py-1">
                                                <Link to="ad-policy" className="text-white" rel="nofollow">Ad Policy</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/cookie-policy" className="text-white" rel="nofollow">Cookie Policy</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/privacy-policy" className="text-white">Privacy Policy</Link>
                                            </li>
                                            <li className="py-lg-2 py-1">
                                                <Link to="/wallet-items-policy" className="text-white">Wallet Items Policy</Link>
                                            </li>

                                        </ul >
                                    </div >
                                </div >
                            </div >
                            <div className="col-12 col-sm-12 col-sm-12 col-md-3">
                                {/* Footer Items  */}
                                <div className="footer-items">
                                    {/* Footer Title  */}
                                    <h3 className="footer-title mb-2 mr-lg-5 pe-lg-5 text-white">Contact Us</h3>
                                    {/* Store Buttons  */}
                                    <p className="text-white pt-lg-2 my-0">
                                        Want to drop suggestions & ideas or want us to address any query or concern. We are just an email away,
                                    </p>

                                    <p className="text-white my-0">
                                        <Link to={{ pathname: "mailto:support@voo-hoo.com" }} target="_blank" className="text-white text-decoration-underline">support@voo-hoo.com</Link>
                                    </p>

                                    {/* Social Icons  */}
                                    <div className="social-icons d-flex ml-icons">
                                        <Link to={{ pathname: "https://www.facebook.com/VooHooApp/" }} target="_blank">
                                            <Icon
                                                path={FacebookIcon}
                                                size={1}
                                                color="#fff"
                                            />
                                        </Link>
                                        <Link to={{ pathname: "https://www.instagram.com/voohooofficial/" }} target="_blank">
                                            <Icon
                                                path={InstagramIcon}
                                                size={1}
                                                color="#fff"
                                            />
                                        </Link>
                                        <Link to={{ pathname: "https://www.youtube.com/channel/UCB0OF3SNEHnbaq9FSYKCUDA" }} target="_blank">
                                            <Icon
                                                path={YouTubeIcon}
                                                size={1}
                                                color="#fff"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom  */}
                <div className="footer-bottom" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area  */}
                                <div className="copyright-area d-block flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left  */}
                                    <div className="copyright-left">&copy; Copyrights 2022 <span >VOOH</span><span className="ls-2">OO</span>. All rights reserved.</div>
                                    {/* Copyright Right  */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </footer >
        </div >
    )
}
