import React, { useState, useRef, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { makeStyles, Modal, Box, Grid } from "@material-ui/core";
import Button from 'mui-button';

import CookieService from '../../../Adapters/Admin/Services/Cookie';
import { AuseAuthDispatch } from '../../../Contexts/Admin/AdminAuthContext';
import { signOut } from '../../../Store/Admin/Auth/auth';
import { RefreshToken } from '../../../Adapters/Admin/Api/Login'
import { decryptedString } from '../../../Adapters/Admin/Services'

export default function IdleTime() {
  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:00:00');
  const useStyles = makeStyles((theme) => ({
    box_style: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      border: 'none',
      boxShadow: '1px 1px 1px 1px #acacac',
      background: 'white',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '5px',
      "&:focus-visible": {
        border: 'none',
        outline:'none'
      },
    },
    button_outlined: {
      margin: '10px',
      fontSize: '9px',
      width: '100%'
    },
    h_6: {
      fontSize: '12px !important',
      fontWeight: '300 !important'
    }

  }));

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }
  const startTimer = (e) => {
    let { total, hours, minutes, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to 
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }

  const clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next    
    setTimer('00:1:00');

    // If you try to remove this line the 
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  }
  var userDispatch = AuseAuthDispatch();
  const classes = useStyles();
  const currentdate = new Date(Date.now())
  const expiryDate=CookieService.getExpiryDate()
  //const [isLoggedIn, setIsLoggedIn] = useState(CookieService.getAccessToken() ? true : false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)
  useEffect(() => {
    if (modalIsOpen)
      clearTimer(getDeadTime());
  }, [modalIsOpen]);
  const onIdle = () => {
    setModalIsOpen(true)
    sessionTimeoutRef.current = setTimeout(logOut, 1 * 60 * 1000);
  }
  const stayActive = () => {
    RefreshToken().then(response => {
      const value = JSON.parse(decryptedString(response))
      CookieService.setToken(value.data.token)
    })
    setModalIsOpen(false)
    clearTimeout(sessionTimeoutRef.current)
  }
  const logOut = () => {
    signOut(userDispatch, "Session Expired")
    setModalIsOpen(false)
    //setIsLoggedIn(false)
    clearTimeout(sessionTimeoutRef.current)
  }
  return (
    <div>
      <Modal
        open={modalIsOpen}
        //onClose={() => setModalIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={classes.box_style}>
          <h5 style={{ padding: '10px 0' }}>Your session expires in</h5>
          <h6 className={classes.h_6}>{timer}</h6>
          <Grid container spacing={6} style={{ textAlign: "center" }}>
            <Grid item xs={6}>
              <Button
                className={classes.button_outlined}
                color="error"
                onClick={logOut}>
                Log me out</Button></Grid>
            <Grid item xs={6}>
              <Button
                className={classes.button_outlined}
                color="primary"
                onClick={stayActive}>
                Keep me Logged In</Button></Grid>
          </Grid>
        </Box>
      </Modal>
      <IdleTimer
        ref={idleTimerRef}
      timeout={expiryDate - currentdate.getTime() < 1? 1*60*1000:60*60*24*1000}
        //timeout={1*60*1000}
        onIdle={onIdle}></IdleTimer>
    </div>
  )
}
