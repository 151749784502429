import React, { useEffect, useState, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Badge
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Notifications as NotificationIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import {Link} from 'react-router-dom';

// styles
import useStyles from '../../../Assets/Styles/Admin/HeaderStyle';

// components
import { Typography } from "../Wrappers";
import UserAvatar from "../UseAvatar";

// context
import {
  AuseLayoutState,
  AuseLayoutDispatch,
  toggleSidebar,
} from "../../../Contexts/Admin/AdminLayoutContext";
import { AuseAuthDispatch, AuseAuthState } from "../../../Contexts/Admin/AdminAuthContext";
import { signOut } from "../../../Store/Admin/Auth/auth";
import { AllWithdrawal, UpdateWithdrawal } from '../../../Adapters/Admin/Api/Withdrawal'
import { decryptedString } from "../../../Adapters/Admin/Services";

function Header(props) {
  const initialState = {
    isLoading: false,
    pageno: 1,
    rowsperPage: 10,
    status: 0,
  };
  const [data, setData] = useState(initialState);
  var classes = useStyles();

  // global
  var layoutState = AuseLayoutState();
  var layoutDispatch = AuseLayoutDispatch();
  var userDispatch = AuseAuthDispatch();
  var { userName } = AuseAuthState();
  var {profileImage} =AuseAuthState();

  // local
  var [notification, setNotification] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  var [withdrawaldata, setWithdrawalData] = useState([])
  var[notificationCount,setNotificationCount]=useState(0);

  useEffect(() => {
    getWithdrawal()
  }, [])
  const getWithdrawal = useCallback(() => {
    const order="flag"
    AllWithdrawal({ data, order}).then(response => {
      const getdata = JSON.parse(decryptedString(response))
      let withdrawal_data = []
      let dd = []
      let count=0
      getdata.data.docs.map((arr, index) => {
        dd.push(arr._id, arr.user_id.username, arr.amount, arr.flag)
        if(arr.flag==="true")
        {
          count=count+1
        }
        else{
          count=count
        }    
        withdrawal_data.push(dd)
        dd = []        
      })
      setWithdrawalData(withdrawal_data)
      setNotificationCount(count)
    })
  }, [])
  const handleFlag = (withdrawalid) => {
    const flag = "false"
    UpdateWithdrawal({ withdrawalid, flag }).then(response => {
      const getdata = JSON.parse(decryptedString(response))
      if (getdata.status == 1) {
        getWithdrawal()
      }
    })
  }
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>

        <Typography variant="h6" weight="medium" className={classes.logotype}> Admin</Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setNotification(e.currentTarget)}
          aria-label="notification"
        >
          <Badge badgeContent={notificationCount} color="success">
          <NotificationIcon classes={{ root: classes.headerIcon }} />
        </Badge>
      </IconButton>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        className={classes.headerMenuButton}
        aria-controls="profile-menu"
        onClick={e => setProfileMenu(e.currentTarget)}
      >
        {/* <AccountIcon classes={{ root: classes.headerIcon }} /> */}
        {profileImage?<img src={profileImage} classes={{ root: classes.headerIcon }} />:<AccountIcon classes={{ root: classes.headerIcon }} />}
     
      </IconButton>
      <Menu
        id="mail-menu"
        open={Boolean(notification)}
        anchorEl={notification}
        onClose={() => setNotification(null)}
        MenuListProps={{ className: classes.headerMenuList }}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        {withdrawaldata.length > 0 && withdrawaldata.map((data) => (
          <Link to="/admin/miscellaneous/withdrawal">
          <MenuItem
            onClick={() => handleFlag(data[0])}
            style={{ color: data[3] === "true" ? "#7b30ad" : "#4A4A4A" }}
          >
            {data[1]} has requested withdrawal amount of Rs.{data[2]}</MenuItem></Link>
        ))}
      </Menu>

      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        <div className={classes.profileMenuUser}>
          <Typography variant="h4" weight="medium">
            {userName}
          </Typography>
        </div>
        <MenuItem
        // className={classNames(
        //   classes.profileMenuItem,
        //   classes.headerMenuItem,
        // )}
        >
          <AccountIcon className={classes.profileMenuIcon} /> Profile
        </MenuItem>

        <div className={classes.profileMenuUser}>
          <Typography
            className={classes.profileMenuLink}
            color="primary"
            onClick={() => signOut(userDispatch)}
          >
            Sign Out
          </Typography>
        </div>
      </Menu>

    </Toolbar>
    </AppBar >
  );
}
export default Header