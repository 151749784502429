import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { ThemeProvider, StyledEngineProvider } from '@material-ui/styles';
import { CssBaseline } from "@material-ui/core";
import Themes from './Themes/Admin'
import App from './Pages/App';
import reportWebVitals from './reportWebVitals';
import { ALayoutProvider } from './Contexts/Admin/AdminLayoutContext'
import { AAuthProvider } from './Contexts/Admin/AdminAuthContext'
import { FLayoutProvider } from './Contexts/Admin/FilterStateContext'

const store = createStore(() => [], {}, applyMiddleware());

ReactDOM.render(
  <ALayoutProvider>
    <AAuthProvider>
      <FLayoutProvider>
        <React.StrictMode>
          <Provider store={store}>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Provider>
        </React.StrictMode>
      </FLayoutProvider>
    </AAuthProvider>
  </ALayoutProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
