export const permissions = [
    {
      name: "add"
    },
    {
      name: "edit"
    },
    {
      name: "delete"
    },
    {
      name: "view"
    },
    {
      name: "manage_role"
    },
    {
      name: "admin_login"
    },
    {
      name: "agency_login"
    },
    {
      name: "manage_post"
    },
    {
      name: "manage_user"
    },
    {
      name: "manage_comment"
    },
    {
      name: "monetary"
    },
    {
      name: "manage_package"
    },
    {
      name: "manage_stickers"
    },
    {
      name: "manage_mood"
    },
    {
      name:"manage_language"
    },
    {
      name: "add_coin_user"
    },
    {
      name:"manage_wallet_offers"
    }
  ];
  