import axios from '../../../../Utils/Admin/axios.config'
import CookieService from '../../Services/Cookie';

export const RefreshToken = async () => {  
    let token = CookieService.getAccessToken()    
    var response = axios.post(`auth/renew-jwt/`,{},{
        headers:{
            'Authorization': `Bearer ${token}`
        }
    }).then(res => {
        return res.data
    }).catch(err => { console.log(err) });
    return response
}