import axios from '../../../../Utils/Admin/axios.config'

export const UpdateWithdrawal = async (data) => {
    var response = axios.patch(`withdrawal/${data.withdrawalid}`, {      
        ...(data.status ? { status: data.status } : {}),
        ...(data.flag ? { flag: data.flag } : {}),
    }).then(res => {
        return res.data
    }).catch(err => { console.log(err) });
    return response    
}
