import React from 'react'
import {
    Route,
} from 'react-router-dom'
//import layout 
import AdminLayout from '../AdminLayout';
//import Private Route
import { PrivateRoute } from '../PrivateRoute/PrivateRoute';

const Login = React.lazy(() => import('../../../Pages/Admin/Login'))
const ForgotPassword=React.lazy(()=>import('../../../Pages/Admin/ForgotPassword'))
const ResetPassword=React.lazy(()=>import('../../../Pages/Admin/ResetPassword'))
const Dashboard = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Dashboard')))
const Users = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Users')))
const UserUpdate = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserUpdate')))
const UserRoles = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserRoles')))
const UserLive = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserLiveSessions')))
const UserBlockedIp=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/BlockedIp')))
const UserRolesUpdate = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserRolesUpdate')))
const UserBulkUpdate = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UserBulkUpdate')))
const AddRoles = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddUserRole')))
const PushNotification = AdminLayout(React.lazy(() => import('../../../Pages/Admin/PushNotification')))
const Post = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Posts')))
const Packages = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Packages')))
const AddPackage = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddPackage')))
const UpdatePackage = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdatePackage')))
const Stickers = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Stickers')))
const AddSticker = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddSticker')))
const AddBulkSticker = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddBulkSticker')))
const UpdateSticker = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateSticker')))
const Banners = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Banners')))
const AddBanner = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddBanner')))
const AddBulkBanner = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddBulkBanner')))
const UpdateBanner = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateBanner')))
const Diamond = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Diamond')))
const AddDiamond = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddDiamond')))
const UpdateDiamond = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateDiamond')))
const Coupon = AdminLayout(React.lazy(() => import('../../../Pages/Admin/CouponCode')))
const AddCoupon = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddCouponCode')))
const UpdateCoupon = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateCouponCode')))
const Agency = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Agency')))
const AddAgency=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/AddAgency')))
const Withdrawal=AdminLayout(React.lazy(() => import('../../../Pages/Admin/Withdrawal')))
const GoLiveStats=AdminLayout(React.lazy(() => import('../../../Pages/Admin/GoLiveStats')))
const Mood = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Mood')))
const DiamondStats=AdminLayout(React.lazy(() => import('../../../Pages/Admin/DiamondStats')))
const HistoricalData=AdminLayout(React.lazy(() => import('../../../Pages/Admin/HistoricalData')))
const AddFreeGoodies=AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddFreeGoodies')))
const FreeGoodies=AdminLayout(React.lazy(() => import('../../../Pages/Admin/FreeGoodies')))
const AddBulkFreeGoodies=AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddBulkGoodies')))
const ReadMe=AdminLayout(React.lazy(() => import('../../../Pages/Admin/ReadMe')))
const UpdateMood=AdminLayout(React.lazy(() => import('../../../Pages/Admin/Mood/UpdateMood')));
const AddMood=AdminLayout(React.lazy(() => import('../../../Pages/Admin/Mood/AddMood')))
const Languages=AdminLayout(React.lazy(()=> import('../../../Pages/Admin/Languages')))
const AddLanguage=AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddLanguages')))
const UpdateLanguage=AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateLanguages')))
const WalletOffers = AdminLayout(React.lazy(() => import('../../../Pages/Admin/WalletOffers')))
const AddWalletOffers = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddWalletOffers')))
const UpdateWalletOffers = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateWalletOffers')))
const UserBadges = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Badges')))
const AddUserBadges = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddBadges')))
const UpdateUserBadges = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateBadges')))
const SendEmail=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/SendEmail')))
const AppVersion = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AppVersion')))
const AddAppVersion = AdminLayout(React.lazy(() => import('../../../Pages/Admin/AddAppVersion')))
const UpdateAppVersion = AdminLayout(React.lazy(() => import('../../../Pages/Admin/UpdateAppVersion')))
const FollowerCount=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/FollowerCount')))
const CreatorsStat=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/CreatorsStat')))
const Events=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/Events')))
const UpdateEvents=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/UpdateEvents')))
const AddEvent=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/AddEvents')))
const TestGmail=AdminLayout(React.lazy(()=>import('../../../Pages/Admin/TestGmail/TestGmail')))


const adminRoutes = [
    { path: "/admin/login", component: Login, exact: true, secureRoute: false },
    { path: "/admin/forgotpassword", component: ForgotPassword, exact: true, secureRoute: false },
    { path: "/admin/resetpassword", component: ResetPassword, exact: true, secureRoute: false },
    { path: '/admin', component: Dashboard, exact: true, secureRoute: true },
    { path: '/admin/dashboard', component: Dashboard, exact: true, secureRoute: true },
    { path: '/admin/users', component: Users, exact: true, secureRoute: true },
    { path: '/admin/users/list', component: Users, exact: true, secureRoute: true },
    { path: '/admin/users/list/update/:userId', params: "userId", component: UserUpdate, exact: true, secureRoute: true },
    { path: '/admin/users/roles', component: UserRoles, exact: true, secureRoute: true },
    { path: '/admin/users/roles/update/:roleId', params: "roleId", component: UserRolesUpdate, exact: true, secureRoute: true },
    { path: '/admin/users/roles/add/', component: AddRoles, exact: true, secureRoute: true },
    { path: '/admin/users/live', component: UserLive, exact: true, secureRoute: true },
    { path: '/admin/users/blockedip', component: UserBlockedIp, exact: true, secureRoute: true },
    { path: '/admin/users/bulk_update', component: UserBulkUpdate, exact: true, secureRoute: true },
    { path: '/admin/users/follower_count', component: FollowerCount, exact: true, secureRoute: true },
    { path: '/admin/pushnotification', component: PushNotification, exact: true, secureRoute: true },
    { path: '/admin/pushnotification/:typeId', component: PushNotification, exact: true, secureRoute: true },
    { path: '/admin/posts', component: Post, exact: true, secureRoute: true },
    { path: '/admin/packages', component: Packages, exact: true, secureRoute: true },
    { path: '/admin/package/update/:packageId', component: UpdatePackage, exact: true, secureRoute: true },
    { path: '/admin/package/add/', component: AddPackage, exact: true, secureRoute: true },
    { path: '/admin/stickers', component: Stickers, exact: true, secureRoute: true },
    { path: '/admin/sticker/update/:stickerId', params: "stickerId", component: UpdateSticker, exact: true, secureRoute: true },
    { path: '/admin/sticker/add/', component: AddSticker, exact: true, secureRoute: true },
    { path: '/admin/sticker/addbulk/', component: AddBulkSticker, exact: true, secureRoute: true },
    { path: '/admin/banners', component: Banners, exact: true, secureRoute: true },
    { path: '/admin/banner/update/:bannerId', params: "bannerId", component: UpdateBanner, exact: true, secureRoute: true },
    { path: '/admin/banner/add/', component: AddBanner, exact: true, secureRoute: true },
    { path: '/admin/banner/addbulk/', component: AddBulkBanner, exact: true, secureRoute: true },
    { path: '/admin/diamond', component: Diamond, exact: true, secureRoute: true },
    { path: '/admin/diamond/update/:diamondId', params: "diamondId", component: UpdateDiamond, exact: true, secureRoute: true },
    { path: '/admin/diamond/add/', component: AddDiamond, exact: true, secureRoute: true },
    { path: '/admin/coupon', component: Coupon, exact: true, secureRoute: true },
    { path: '/admin/coupon/add/', component: AddCoupon, exact: true, secureRoute: true },
    { path: '/admin/coupon/update/:couponId', params: "couponId", component: UpdateCoupon, exact: true, secureRoute: true },
    { path: '/admin/agency', component: Agency, exact: true, secureRoute: true },
  //  { path: '/admin/agency/add/', component: AddAgency, exact: true, secureRoute: true },
    { path: '/admin/miscellaneous/withdrawal',component: Withdrawal, exact: true, secureRoute: true },
    { path: '/admin/miscellaneous/golivestats', component: GoLiveStats, exact: true, secureRoute: true  },
    { path: '/admin/miscellaneous/creatorstats', component: CreatorsStat, exact: true, secureRoute: true  },
    { path: '/admin/miscellaneous/historicaldata', component: HistoricalData, exact: true, secureRoute: true  },
    { path: '/admin/mood/add', component: AddMood, exact: true, secureRoute: true },
    { path: '/admin/moods', component: Mood, exact: true, secureRoute: true },
    { path: '/admin/mood/update/:moodId', params: "moodId", component: UpdateMood, exact: true, secureRoute: true },
    { path: '/admin/miscellaneous/diamondstats', component: DiamondStats, exact: true, secureRoute: true  },
    { path: '/admin/freegoodies/add',  component: AddFreeGoodies, exact: true , secureRoute: true },
    { path: '/admin/freegoodies/add/:typeId',  component: AddFreeGoodies, exact: true , secureRoute: true },
    {path:'/admin/freegoodies',  component: FreeGoodies, exact: true , secureRoute: true },
    {path:'/admin/freegoodies/addbulk',  component: AddBulkFreeGoodies, exact: true , secureRoute: true },
    { path: '/admin/languages',  component: Languages, exact: true , secureRoute: true },
    { path: '/admin/languages/add/', component: AddLanguage, exact: true, secureRoute: true },
    { path: '/admin/languages/update/:languageId', component: UpdateLanguage, exact: true, secureRoute: true },
    { path: '/admin/wallet-offers', component: WalletOffers, exact: true, secureRoute: true },
    { path: '/admin/wallet-offers/update/:walletOfferId', component: UpdateWalletOffers, exact: true, secureRoute: true },
    { path: '/admin/wallet-offers/add/', component: AddWalletOffers, exact: true, secureRoute: true },
    { path: '/admin/user-badges', component: UserBadges, exact: true, secureRoute: true },
    { path: '/admin/user-badges/update/:userBadgeId', component: UpdateUserBadges, exact: true, secureRoute: true },
    { path: '/admin/user-badges/add/', component: AddUserBadges, exact: true, secureRoute: true },
    { path: '/admin/app-versions', component: AppVersion, exact: true, secureRoute: true },
    { path: '/admin/app-versions/add/', component: AddAppVersion, exact: true, secureRoute: true },
    { path: '/admin/app-versions/update/:versionid', params: "versionid", component: UpdateAppVersion, exact: true, secureRoute: true },
    { path: '/admin/event', component: Events, exact: true, secureRoute: true },
    { path: '/admin/event/update/:eventId', component: UpdateEvents, exact: true, secureRoute: true },
    { path: '/admin/event/add/', component: AddEvent, exact: true, secureRoute: true },
 //  { path: '/admin/send-email/', component: SendEmail, exact: true, secureRoute: true },
    { path: '/admin/readme',  component: ReadMe, exact: true , secureRoute: true },
   // { path: '/admin/gmailtest',  component: TestGmail, exact: true , secureRoute: true },
];

export default function AdminRoutes() {
    return (
        <>
            {adminRoutes.map((item, key) => {
                if (typeof item.secureRoute !== 'undefined' && item.secureRoute == true)
                    return <PrivateRoute exact={item.exact} path={item.path} component={item.component} key={key} />
                else
                    return <Route exact={item.exact} path={item.path} component={item.component} key={key} />
            })}
        </>
    )
}