import React,{Suspense} from "react";
import classnames from "classnames";

// styles
import useStyles from '../../../Assets/Styles/Admin/LayoutStyle';

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import IdleTime from "../IdleTime";

const AdminLayoutvar=(props)=>{
  var classes = useStyles();
  const [dtData, setdtData] = React.useState({});  
  return (
    <div className={classes.root}>  
    <IdleTime />
          <Header history={props.history} />          
          <Sidebar />
          <div
            className={classnames(classes.content)}
          >  
            <div className={classes.fakeToolbar} />        
            {props.children}               
          </div>        
    </div>
  );
}

//HOC
const AdminLayout = Component => ({ ...props }) => (
  <AdminLayoutvar>
      <Suspense fallback="">
          <Component {...props} />
      </Suspense>
  </AdminLayoutvar>
)
export default AdminLayout