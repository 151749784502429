const LocalStorageService = (function(){
    var _service;
    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setAuthorization(Obj) {
      localStorage.setItem('isAuthenticated', Obj);      
    }
    function _setRole(obj){
      localStorage.setItem('user_role', obj); 
    }
    function _setSession(obj){
      localStorage.setItem('session', obj); 
    }
    function _getAuthorization() {
      return localStorage.getItem('isAuthenticated');
    }  
    function _getRole(){
     return localStorage.getItem('user_role');       
    } 
    function _getSession(){
      return localStorage.getItem('session');       
     } 
    function _clearAuthorization() {
      localStorage.removeItem('isAuthenticated');      
    }
    function _clearRole(){
      localStorage.removeItem('user_role'); 
    } 
   return {
      getService : _getService,
      setAuthorization : _setAuthorization,
      getAuthorization : _getAuthorization,      
      clearAuthorization : _clearAuthorization,
      setRole : _setRole,
      getRole : _getRole,      
      clearRole : _clearRole,
      setSession:_setSession,
      getSession:_getSession
    }
   })();
   export default LocalStorageService;