import tinycolor from "tinycolor2";

// const primary = "#286786";
// const primary="#D54A74";
const primary = "#7b30ad";
const secondary = "#404669";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#E3319D";
const error="#D43232";
const description="#EDE6D6";

//added 31-12-2021 for new theme
const gradient_start = "#792EB0";
const gradient_center = "#A73C92";
const gradient_end = "#D54A74";
const newBackground = "#141d51";
///
const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    description: {
      main: description,
      light: tinycolor(description)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(description)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9 ",
      //hint:"#141d51 !important",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
    gradient_start: {
      main: gradient_start,
      light: tinycolor(gradient_start)
        .lighten(gradient_start)
        .toHexString(),
      dark: tinycolor(gradient_start)
        .darken(darkenRate)
        .toHexString(),
    },
    gradient_center: {
      main: gradient_center,
      light: tinycolor(gradient_center)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(gradient_center)
        .darken(darkenRate)
        .toHexString(),
    },
    gradient_end: {
      main: gradient_end,
      light: tinycolor(gradient_end)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(gradient_end)
        .darken(darkenRate)
        .toHexString(),
    },
    newBackground: {
      main: newBackground,
      light: tinycolor(newBackground)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(newBackground)
        .darken(darkenRate)
        .toHexString(),
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9 !important",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
        paddingTop: '6px',
        paddingBottom: '6px'
      },
      gutters: {
        paddingLeft: '12px',
        paddingRight: '12px'
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTable: {
      root: {
        border: [[1, 'solid', '#d3d3d3']],
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        // borderBottom: "1px solid rgba(224, 224, 224, .5)",
        borderColor: '#d3d3d3',
        '&:not(:last-child)': {
          borderRight: [[1, 'solid', '#c0c0c0']],
        },
        padding: '2px',
        textAlign:'center'
        // paddingLeft: 24
      },
      head: {
        background: '#d9d9d9 !important',
        fontSize: "0.95rem",
        fontWeight: 600,
        textAlign:'center'
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10
      }
    },
    MUIDataTableToolbar: {
      actions: {
        display: "flex",
        flex: "initial",
        // move all icons to the right
        "& > span, & > button": {
          order: 99
        },
        // target the custom toolbar icon
        "& > span:last-child, & > button:last-child": {
          order: 1
        },
        // target any icon
        "& > span:nth-child(4), & > button:nth-child(4)": {
          order: 2
        }
      }
    },
    MuiInputLabel: {
      root: {
        '@media (max-width:960px)': {
          fontSize: '11px'
        }
      }
    },    
  },
};

export default defaultTheme;
