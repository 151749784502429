import React, { Suspense } from 'react'
import {
    // HashRouter,
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom'

//routes
import { default as WebRoutes } from '../../Components/Web/Routes'
import { default as AdminRoutes } from '../../Components/Admin/Routes'
import {default as AgencyRoutes} from '../../Components/CommonUser/Routes'
import CircularLoading from '../../Components/Admin/CircularLoading'


function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/admin">
                        <Suspense fallback={<CircularLoading />}>
                            <Switch>
                                <AdminRoutes />
                            </Switch>
                        </Suspense>
                    </Route>
                    <Route path="/agency">
                        <Suspense fallback={<CircularLoading />}>
                            <Switch>
                                <AgencyRoutes />
                            </Switch>
                        </Suspense>
                    </Route>
                    <Route path="/">
                        <Suspense fallback={<CircularLoading />}>
                            <Switch>
                                <WebRoutes />
                            </Switch>
                        </Suspense>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App