import React from 'react';
import {
    Route,
    Redirect
} from 'react-router-dom';

// context
import { AuseAuthState } from '../../../Contexts/Admin/AdminAuthContext'
import CookieService from '../../../Adapters/Admin/Services/Cookie';

function PrivateRoute({ component: Component, roles, ...rest }) {   
    // global
    var isCookie = CookieService.getAccessToken()?true:false;  
    var { isAuthenticated } = AuseAuthState();   
    return (
        <Route {...rest} render={props => {
            if (!isCookie) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/agency/login', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };