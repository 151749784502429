import React from "react";

var FilterStateContext = React.createContext();
var FilterDispatchContext = React.createContext();

function FlayoutReducer(state, action) {
  switch (action.type) {
    case "SAVE_FILTER":
      return { ...state, data: action.state };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FLayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(FlayoutReducer, {
    data: {
      isLoading: false,
      pageno: 1,
      rowsperPage: 10,
      roleId: [],
      profileType: '',
      status: 1,
      filetype: '',
      showBlock: false,
      price: '',
      category:''
    },
  });
  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
}

function FuseLayoutState() {
  var context = React.useContext(FilterStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function FuseLayoutDispatch() {
  var context = React.useContext(FilterDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export { FLayoutProvider, FuseLayoutState, FuseLayoutDispatch, saveFilter };

// ###########################################################
function saveFilter(dispatch, state) {
  //console.log(state,"FilterDispatchContext")
  dispatch({
    type: "SAVE_FILTER",
    state: state
  });
}
