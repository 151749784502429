//import storage from "local-storage-fallback"
import CookieService from "../../Adapters/Admin/Services/Cookie"
import LocalStorageService from "../../Adapters/Admin/Services/LocalStorage"
//import { decryptedString } from '../../Adapters/Admin/Services';
let axios = require('axios');

const baseURL = process.env.REACT_APP_API_BASE_URL;
// let token = CookieService.getAccessToken()
// const expiryDate = Number(CookieService.getExpiryDate())

const axiosApiInstance = axios.create({
    baseURL,
    // withCredentials: true
})

// Add a request interceptor
axiosApiInstance.interceptors.request.use(
    async config => {
        // const value = await redisClient.get(rediskey)
        // const keys = JSON.parse(value)    
        let token = CookieService.getAccessToken()            
        const currentdate = Date.now()
        const expiryDate = Number(CookieService.getExpiryDate())
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            patch: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            post: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            // ,
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
        if (expiryDate - currentdate < 1 && token !== null) {
            // onGetForcedToken().then(res => {
            //     const value = JSON.parse(decryptedString(res))
            //     token = value.data.token
            // });
            // token = typeof data === 'string' ? data : await data();
            CookieService.setToken(token)        
            CookieService.clearToken()
            LocalStorageService.clearAuthorization()
            LocalStorageService.clearRole()        
            localStorage.setItem("session","Session Expired")   
            window.location.href = `/admin/login`;                       
        }
        window.onbeforeunload = function () {
            localStorage.removeItem("session");
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
// Add a response interceptor
// axiosApiInstance.interceptors.response.use((response) => {
//     return response
//   }, async function (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 403 && !originalRequest._retry) {
//       originalRequest._retry = true;
//      // const access_token = await refreshAccessToken();            
//       //axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//       return axiosApiInstance(originalRequest);
//     }
//     return Promise.reject(error);
//   });

// errorComposer will compose a handleGlobally function
const errorComposer = (error) => {
    return () => {
        const statusCode = error.response ? error.response.status : null;
        switch (statusCode) {
            case 400:
                console.log(`error: ${400}`, error);
                break;
            case 401:
                console.log('Please login to access this resource');
                break;
            case 403:
                console.log(`error: ${403}`, error);
                break;
            case 404:
                console.log('The requested resource does not exist or has been deleted');
                break;
            case 500:
                console.log(`error: ${500}`, error);
                break;
            case 503:
                console.log(`error: ${503}`, error);
                break;
            default:
                console.log(`error*: ${statusCode}`, error);
                break;
        }
    }
}
function onGetForcedToken() {
    let token = CookieService.getAccessToken()
    var response = axios.post(`${baseURL}auth/renew-jwt/`, {}, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(res => {
        return res.data
    }).catch(err => { console.log(err) });
    return response
}

export default axiosApiInstance

