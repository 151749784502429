import React from 'react'
import {
    Route,
} from 'react-router-dom'
//import layout 
import AdminLayout from '../../../Components/Admin/AdminLayout';
//import Private Route
import { PrivateRoute } from '../PrivateRoute';

const Login = React.lazy(() => import('../../../Pages/Admin/Login'))
const ForgotPassword=React.lazy(()=>import('../../../Pages/Admin/ForgotPassword'))
const ResetPassword=React.lazy(()=>import('../../../Pages/Admin/ResetPassword'))
const Dashboard = AdminLayout(React.lazy(() => import('../../../Pages/Admin/Dashboard')))


const agencyRoutes = [
    { path: "/agency/login", component: Login, exact: true, secureRoute: false },
    { path: "/agency/forgotpassword", component: ForgotPassword, exact: true, secureRoute: false },
    { path: "/agency/resetpassword", component: ResetPassword, exact: true, secureRoute: false },
    { path: '/agency', component: Dashboard, exact: true, secureRoute: true },
    { path: '/agency/dashboard', component: Dashboard, exact: true, secureRoute: true },
  
];

export default function AgencyRoutes() {
    return (
        <>
            {agencyRoutes.map((item, key) => {
                if (typeof item.secureRoute !== 'undefined' && item.secureRoute == true)
                    return <PrivateRoute exact={item.exact} path={item.path} component={item.component} key={key} />
                else
                    return <Route exact={item.exact} path={item.path} component={item.component} key={key} />
            })}
        </>
    )
}