import React, { Suspense, useState, useRef, useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../../../Assets/Styles/Web/style.css'
import '../../../Assets/Styles/Web/responsive.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Icon from '@mdi/react'
//icons
import {
    mdiArrowUpBold as arrowUp
} from '@mdi/js'

const WebLayoutvar = (props) => {
    const [isSticky, setSticky] = useState(false);
    const [isCollapsed, setCollapsed] = useState(true);
    const [spinner, setSpinner] = useState(true);

    const ref = useRef(null);

    const handleScroll = () => {
        if (ref.current) {     
            setSticky(window.pageYOffset > 100 ? true : false)
        }
    };

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000)
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    const toggleNavbar = () => {
        setCollapsed(!isCollapsed)
        if (isCollapsed === true) {
            document.body.classList.add('canvas-open');
        }
        else {
            document.body.classList.remove('canvas-open');
        }
    }
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    return (
        <div>
            {(spinner && <div className="preloader-main" id="preloader-main">
                <div className="preloader-wapper" id="preloader-wapper">
                    <svg className="preloader" xmlns="http://www.w3.org/2000/svg" version="1.1" width="600" height="200">
                        <defs>
                            <filter id="goo" x="-40%" y="-40%" height="200%" width="400%">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -8" result="goo" />
                            </filter>
                        </defs>
                        <g filter="url(#goo)">
                            <circle className="dot" cx="50" cy="50" r="25" fill="#2BF5E5" />
                            <circle className="dot" cx="50" cy="50" r="25" fill="#286786" />
                        </g>
                    </svg>
                    <div>
                        <div className="loader-section section-left"></div>
                        <div className="loader-section section-right"></div>
                    </div>
                </div>
            </div>)}

            {(isSticky === true && <div id="scrollUp" title="Scroll To Top" onClick={() => scrollTop()}>
                <Icon
                    path={arrowUp}
                    size={1.5}
                    color="#fff"
                />
            </div>)}
            <Header isSticky={isSticky} refee={ref} navClick={toggleNavbar} isCollapsed={isCollapsed} />
            {props.children}
            <Footer />
        </div>
    )
}

//HOC
const WebLayout = Component => ({ ...props }) => (
    <WebLayoutvar>
        <Suspense fallback="">
            <Component {...props} />
        </Suspense>
    </WebLayoutvar>
)
export default WebLayout
