import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../Assets/Images/Web/logo.png'
import Icon from '@mdi/react'
//icons
import {
    mdiFacebook as FacebookIcon,
    mdiInstagram as InstagramIcon,
    mdiYoutube as YouTubeIcon,
} from '@mdi/js'

export default function Header(props) {
    var headClass = 'navbar navbar-sticky navbar-expand-lg navbar-dark';
    if (props.isSticky === true) {
        headClass += ' navbar-sticky-on navbar-sticky-moved-up'
    }
    if (props.isCollapsed === false) {
        headClass += ' active'
    }
    const onlinkClick = (id) => {
        var elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    return (
        <div>
            <header className={headClass} ref={props.refee}>
                {/* <header className={`${props.isSticky ? 'navbar navbar-sticky navbar-expand-lg navbar-dark navbar-sticky-on navbar-sticky-moved-up' : 'navbar navbar-sticky navbar-expand-lg navbar-dark'}`} ref={props.refee}> */}
                {/* <header className="navbar navbar-sticky navbar-expand-lg navbar-dark"> */}
                <div className="container position-relative">
                    <Link to="/" className="navbar-brand">
                        <img className="navbar-brand-regular img-fluid" src={logo} alt="brand-logo" />
                        <img className="navbar-brand-sticky img-fluid" src={logo} alt="sticky brand-logo" />
                    </Link>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation" onClick={props.navClick}>
                        <span className={`${props.isCollapsed === false ? 'navbar-toggler-icon active' : 'navbar-toggler-icon'}`}></span>
                    </button>
                    <div className="navbar-inner">
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation" onClick={props.navClick}>
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <span className={`${props.isCollapsed === false ? 'navbar-toggler-icon active' : 'navbar-toggler-icon'}`}></span>
                        </button>
                        <nav>
                            <ul className="navbar-nav" id="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link scroll" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link scroll" to="/#features" onClick={() => onlinkClick("features")}>
                                        Features
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link scroll" to="/#aboutus" onClick={() => onlinkClick("aboutus")}>
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link scroll" to="/#contactus" onClick={() => onlinkClick("contactus")}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="social-icons ml-icons top-icons">
                        <Link to={{ pathname: "https://www.facebook.com/VooHooApp/" }} target="_blank">
                            <Icon
                                path={FacebookIcon}
                                size={1}
                                color="#fff"                                               
                            />
                        </Link>
                        <Link to={{ pathname: "https://www.instagram.com/voohooofficial/" }} target="_blank">                            
                            <Icon
                                path={InstagramIcon}
                                size={1}
                                color="#fff"
                            />
                        </Link>
                        <Link to={{ pathname: "https://www.youtube.com/channel/UCB0OF3SNEHnbaq9FSYKCUDA" }} target="_blank">                            
                            <Icon
                                path={YouTubeIcon}
                                size={1}
                                color="#fff"
                            />
                        </Link>
                        {/* <Link to="/login" target="_blank" style={{width:"60px"}}>
                           <label className="lblLogin"> Login</label>
                        </Link> */}
                    </div> 
                </div>
            </header>
        </div>
    )
}
