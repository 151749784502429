import axios from '../../../../Utils/Admin/axios.config'

export const AllWithdrawal = async (data) => {
    const values=data.data
    var response = axios.get('withdrawal',
        {
            params: {
                ...(values.pageno ? { page: values.pageno } : {}),
                ...({ status: values.status }),
                ...(data.order ? { odrby:data.order } : {}),                
                ...(values.rowsperPage ? { limit: values.rowsperPage } : {})
            }
        }).then(res => {
            return res.data
        }).catch(err => { console.log(err) });
    return response
}