import React from 'react'
import {
    Route,
} from 'react-router-dom'
//import layout 
import WebLayout from '../WebLayout';

const Home = WebLayout(React.lazy(() => import('../../../Pages/Web/Home')))
const Gdpr = WebLayout(React.lazy(() => import('../../../Pages/Web/Gdpr')))
const Eula = WebLayout(React.lazy(() => import('../../../Pages/Web/Eula')))
const TermsOfService = WebLayout(React.lazy(() => import('../../../Pages/Web/TermsOfService')))
const CopyrightPolicy = WebLayout(React.lazy(() => import('../../../Pages/Web/CopyrightPolicy')))
const AdPolicy = WebLayout(React.lazy(() => import('../../../Pages/Web/AdPolicy')))
const CookiePolicy = WebLayout(React.lazy(() => import('../../../Pages/Web/CookiePolicy')))
const PrivacyPolicy = WebLayout(React.lazy(() => import('../../../Pages/Web/PrivacyPolicy')))
const WalletItemPolicy = WebLayout(React.lazy(() => import('../../../Pages/Web/WalletItemPolicy')))
const RaiseConcern = WebLayout(React.lazy(() => import('../../../Pages/Web/RaiseConcern')))
const AntiPhishing = WebLayout(React.lazy(() => import('../../../Pages/Web/AntiPhishing')))
const TakeDownNotice = WebLayout(React.lazy(() => import('../../../Pages/Web/TakeDownNotice')))
const RechargeCoins= WebLayout(React.lazy(() => import('../../../Pages/Web/RechargeCoins')))
const GiftingMania=WebLayout(React.lazy(()=>import('../../../Pages/Web/GiftingMania')))
const SundaySpotlight=WebLayout(React.lazy(()=>import('../../../Pages/Web/SundaySpotlight')))
const DownloadApk = WebLayout(React.lazy(() => import('../../../Pages/Web/DownloadApk')))
const MonsoonMania=WebLayout(React.lazy(()=>import('../../../Pages/Web/MonsoonMania')))
const CrimeMaster=WebLayout(React.lazy(()=>import('../../../Pages/Web/CrimeMaster')))

const webRoutes = [
    { path: "/", component: Home, exact: true },
    { path: "/gdpr", component: Gdpr, exact: true },
    { path: "/eula", component: Eula, exact: true },
    { path: "/terms-of-use", component: TermsOfService, exact: true },
    { path: "/Copyright-policy", component: CopyrightPolicy, exact: true },
    { path: "/ad-policy", component: AdPolicy, exact: true },
    { path: "/cookie-policy", component: CookiePolicy, exact: true },
    { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
    { path: "/wallet-items-policy", component: WalletItemPolicy, exact: true },
    { path: "/raise-your-concern", component: RaiseConcern, exact: true },
    { path: "/anti-phishing", component: AntiPhishing, exact: true },
    { path: "/takedownnotice", component: TakeDownNotice, exact: true },
    { path: "/recharge-coins", component: RechargeCoins, exact: true },
    { path: "/gifting-mania", component: GiftingMania, exact: true },
    { path: "/sunday-spotlight", component: SundaySpotlight, exact: true },
    { path: "/download-app", component: DownloadApk, exact: true },
    { path: "/monsoon-mania", component: MonsoonMania, exact: true },
    { path: "/crime-master", component: CrimeMaster, exact: true },
];

export default function WebRoutes() {
    return (
        <>
            {webRoutes.map((item, key) => {
                return <Route exact={item.exact} path={item.path} component={item.component} key={key} />
            })}
        </>
    )
}